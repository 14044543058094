<template>
  <q-page  class="q-pl-xs q-pr-xs q-pl-md q-pr-md">
    <div class="row items-center q-gutter-xs q-gutter-md" v-if="!loading">

      <q-card class="my-card">
        <q-card-section>
          <q-item>
            <q-item-section class="q-pt-lg q-pl-lg">
              <div class="text-h5 text-bold">Payment Method</div>
              <br>
              <div class="text-subtitle1 text-weight-thin">Change primary card or add a new card</div>
            </q-item-section>
          </q-item>
        </q-card-section>

        <div :key="i" v-for="(card, i) of currentItems">
          <q-card-section class="no-padding">
            <q-item>
              <q-item-section side class="no-padding">
                <CreditCard :card="card" />
                <CardDetails class="xs" :card="card"/>
              </q-item-section>
              <q-item-section class="gt-xs" >
                <CardDetails :card="card"/>
              </q-item-section>
            </q-item>
          </q-card-section>
        </div>

        <q-card-section class="q-pt-none">
          <q-item class="no-padding" id="btn_new_card" clickable @click="isShowForm =!isShowForm">
            <q-item-section >
              <q-icon name="cp_new_card" :size="$q.screen.width < 330 ? '270px' : '300px'" style="height: 210px" />
            </q-item-section>
          </q-item>
        </q-card-section>

        <q-btn-group rounded class="row full-width" push v-if="itemsInPage < cards.length" >
          <q-btn class="col text-white" :class="currentPage === 0 ? '' : 'bgBtnLiner'" :color="currentPage === 0 ? 'grey' : ''" size="sm" label="Prev" @click="navTo(currentPage - 1)" rounded :disable="currentPage === 0" />
          <q-btn class="col" :label="`page ${currentPage + 1} of ${pages}`" size="sm" disable/>
          <q-btn class="col text-white" :class="currentPage === pages - 1 ? '' : 'bgBtnLiner'" :color="currentPage === pages - 1 ? 'grey' : ''" label="Next" @click="navTo(currentPage + 1)" size="sm" :disable="currentPage === pages - 1"/>
        </q-btn-group>
      </q-card>

      <q-dialog :value="!!isShowForm" persistent  :full-width="$q.screen.xs"  @hide="isShowForm = false">
        <AddCreditCard v-if="isShowForm" @success="onSuccess" />
      </q-dialog>

    </div>
  </q-page>
</template>

<script>
import filters from '../mixins/filters'
import failedRequest from '../mixins/failed-request'
import CreditCard from '../components/CreditCard'
import AddCreditCard from '../components/AddCreditCard'
import CardDetails from '../components/CardDetails'

export default {
  name: 'PageCreditCards',
  meta: {
    title: 'Credit Cards'
  },
  components: {
    CreditCard, AddCreditCard, CardDetails
  },
  mixins: [filters, failedRequest],
  async mounted () {
    this.$q.loading.show({
      delay: 400
    })
    await this.$store.dispatch('loans/getLoans')
    this.$q.loading.hide()

    if (!this.hasOpenLoan) {
      this.$router.push({ name: '404' })
    }
    this.$dispatch({
      action: 'cards/getCards',
      message: 'Loading Cards...'
    })
  },
  data () {
    return {
      loading: true,
      itemsInPage: 4,
      currentPage: 0,
      isShowForm: false
    }
  },
  computed: {
    cards () {
      return this.$store.state.cards.cards ? this.$store.state.cards.cards : []
    },
    hasOpenLoan () {
      return this.$store.state.loans.loans.filter(l => l.isOpen()).length > 0
    },
    pages () {
      return parseInt(this.cards.length / this.itemsInPage) + 1
    },
    currentItems () {
      let currentItem = this.currentPage * this.itemsInPage
      return this.cards.slice(currentItem, currentItem + this.itemsInPage)
    }
  },
  methods: {
    navTo (index) {
      this.currentPage = index
    },
    onSuccess () {
      this.isShowForm = false
      this.$dispatch([
        {
          action: 'cards/getCards',
          message: 'Refreshing Cards...',
          params: true
        }
      ])
    }
  }
}
</script>

<style scoped>
  .mobile .my-card, .screen--xs .my-card, .screen--sm .my-card, .screen--md .my-card  {
    width: 100%;
  }

  .screen--lg .my-card, .screen--xl .my-card {
    width: 95%;

  }
</style>
