<template>
    <q-card :class="$q.screen.xs ? 'mobile-view' : ''">
      <q-card-section>
        <div class="text-h6" clickable @click="openForMobile" :class="$q.screen.gt.xs ? '' : 'text-center all-pointer-events'">
          Card Details
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section v-if="open">
        <q-list>
          <q-item>
            <q-item-section >
              <div class="card-details-color">Payment Type</div>
            </q-item-section>

            <q-item-section avatar>
              <q-item-label> {{ card.type() }} Ending in {{ card.lastDigits() }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <div class="card-details-color">Card Holder</div>
            </q-item-section>

            <q-item-section avatar>
              <q-item-label>{{ card.get('name') }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <div class="card-details-color">Expires</div>
            </q-item-section>

            <q-item-section avatar>
              <q-item-label>{{ card.expiry() }} </q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="!card.isDefault()">
            <q-item-section>
              <q-btn label="Delete card" icon="delete" class="text-center bg-red text-white" @click="deleteCard"/>
            </q-item-section>
          </q-item>

          <q-item class="xs">
            <q-item-section class="text-h6 text-center all-pointer-events" clickable @click="open = !open">
              <q-item-label>   Hide Details </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
</template>

<script>
export default {
  name: 'card-details',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      open: this.$q.screen.gt.xs
    }
  },
  methods: {
    openForMobile () {
      if (this.$q.screen.gt.xs) {
        return
      }
      this.open = true
    },
    async deleteCard () {
      if (this.card.isDefault()) {
        return
      }

      let success = await this.$dispatch({
        action: 'cards/deleteCard',
        params: this.card.id(),
        message: 'Deleting Card. This might take a while...',
        successMessage: 'Credit Card was deleted'
      })

      if (success) {
        window.dataLayer.push({ event: 'deleted_card' })
      }
    }
  }
}
</script>

<style scoped>
.card-details-color {
  color: #3CCDEF;
}

.mobile-view {
  width: 100%;
}
</style>
