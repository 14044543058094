<template>
  <div class="cardOverflow">
    <img :src="`../statics/${currentIcon}.png`"  class="credit-card-icon"/>

    <div class="cardNumber text-white text-subtitle1">
      * * * *  &nbsp;&nbsp;&nbsp;&nbsp;
      * * * * &nbsp;&nbsp;&nbsp;&nbsp;
      * * * * &nbsp;&nbsp;   {{ card.lastDigits() }}</div>
    <div class="cardExpiry text-white">
      <span class="text-weight-thin">VALID THRU</span>
      <br>
      {{ card.expiry()}}
    </div>
    <div class="cardPrimary text-white">
      <q-checkbox left-label :val="card.isDefault()" @input="setDefault"
               :value="true" size="lg"
               :color="card.isDefault() ? 'light-green-13' : 'grey-1'" :label="card.isDefault() ? 'Primary' : ''" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cardTypes: [
        {
          name: 'Visa Debit',
          icon: 'cp_visa_card'
        },
        {
          name: 'Visa Electron',
          icon: 'cp_visa_card'
        },
        {
          name: 'Mastercard',
          icon: 'cp_master_card'
        },

        {
          name: 'Switch / Maestro',
          icon: 'cp_master_card'
        }
      ]
    }
  },
  computed: {
    currentIcon () {
      return this.cardTypes.find(type => type.name === this.card.type()).icon
    }
  },
  methods: {
    setDefault () {
      if (this.card.isDefault() || !this.card.isValid()) return

      this.$dispatch({
        action: 'cards/setDefault',
        params: this.card.id(),
        message: `Setting card ${this.card.lastDigits()} as primary...`,
        successMessage: `Credit card ${this.card.lastDigits()} was set as primary`
      })
    }
  }
}
</script>

<style scoped>
.credit-card-icon {
  height: 220px;
  width: 100%;
  max-width: 300px;
}
.cardOverflow {
   position: relative;
   overflow: hidden;
   width: 100%;
   max-width: 300px;
   height: 210px;
   margin-bottom: -14px;
   /*margin-left: 15px;*/
   z-index: 10;
 }
.cardExpiry {
  position: absolute;
  bottom: 25px;
  left: 40px;
}
.cardCvv {
  position: absolute;
  bottom: 25px;
  left: 230px;
}
.cardPrimary {
  position: absolute;
  bottom: 140px;
  right: 30px;
}
.cardNumber {
  position: absolute;
  bottom: 70px;
  left: 40px;
  text-align: justify;
}
>>> .q-checkbox__bg {
  border-radius: 10px !important;
  width: 22px;
  height: 22px;
}
</style>
